import PropTypes from "prop-types"
import React from "react"

function SvgComponent ({ size = 18, color = "#fff", ...props }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			height={size}
			width={size}
			fill={color}
			{...props}
		>
			<path d="M341.333 0v102.4h102.4z" />
			<path
				d="M290.133 153.6V0H68.267v512h375.467V153.6H290.133zM358.4 349.867h-76.8v76.8h-51.2v-76.8h-76.8v-51.2h76.8v-76.8h51.2v76.8h76.8v51.2z" />
		</svg>
	)
}

SvgComponent.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

export default SvgComponent
