import PropTypes from "prop-types"
import React from "react"

function SvgComponent ({ size = 18, color = "#fff", ...props }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			height={size}
			width={size}
			fill={color}
			{...props}
		>
			<path
				d="M438.944 352c11.046 0 20-8.954 20-20V80c0-44.112-35.888-80-80-80H133.056c-44.112 0-80 35.888-80 80v352c0 44.112 35.888 80 80 80h245.888c44.113 0 80-35.888 80.001-80 0-11.046-8.954-20-20-20s-20 8.954-20 20c0 22.056-17.944 40-40 40H133.056c-22.056 0-40-17.944-40-40V80c0-22.056 17.944-40 40-40h245.889c22.056 0 40 17.944 40 40v252c-.001 11.046 8.954 20 19.999 20z" />
			<path
				d="M358.944 120h-206c-11.046 0-20 8.954-20 20s8.954 20 20 20h206c11.046 0 20-8.954 20-20s-8.955-20-20-20zM358.944 200h-206c-11.046 0-20 8.954-20 20s8.954 20 20 20h206c11.046 0 20-8.954 20-20s-8.955-20-20-20zM278.054 280h-125.11c-11.046 0-20 8.954-20 20s8.954 20 20 20h125.112c11.046 0 19.999-8.954 19.999-20-.001-11.046-8.955-20-20.001-20z" />
		</svg>
	)
}

SvgComponent.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

export default SvgComponent
